import React, { useState } from 'react';
import { PrimaryLinkCTA, SecondaryLinkCTA } from './cta';
import gmail from '../../images/misc/gmail-icon.svg';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

export const EmailCTA = ({
  to,
  subject,
  cc,
  body,
  ctaText,
  secondaryCtaText = null,
  disabled = false,
}) => {
  if (secondaryCtaText === null) {
    secondaryCtaText = 'Use Gmail';
  }
  // Remove entries in 'cc' if they're also in 'to'
  cc = cc.filter((e) => !to.includes(e));

  const toEmails = (to || []).join(',');
  const ccEmails = (cc || []).join(',');
  const isMobile = useCheckMobileScreen();

  const trimmedBody = body
    .split('\n')
    .map((l) => l.trim())
    .join('\n');

  const mailToParams = new URLSearchParams();
  mailToParams.append('cc', ccEmails);

  const gmailParams = new URLSearchParams();
  gmailParams.append('view', 'cm');
  gmailParams.append('fs', '1');
  gmailParams.append('to', toEmails);
  gmailParams.append('cc', ccEmails);
  gmailParams.append('su', subject);
  gmailParams.append('body', trimmedBody);

  return (
    <div className="flex items-center">
      <PrimaryLinkCTA
        href={`mailto:${toEmails}?${mailToParams.toString()}&subject=${encodeURI(
          subject
        )}&body=${encodeURI(trimmedBody)}`}
        target="_blank"
        disabled={disabled}
      >
        {ctaText}
      </PrimaryLinkCTA>
      <span className="px-1 py-1"></span>
      {!isMobile && (
        <SecondaryLinkCTA
          href={`https://mail.google.com/mail/?${gmailParams.toString()}`}
          target="_blank"
          disabled={disabled}
        >
          <img src={gmail} className="w-6 !mt-0 !mb-0 mr-4" />
          <span className="!no-underline">{secondaryCtaText}</span>
        </SecondaryLinkCTA>
      )}
    </div>
  );
};
